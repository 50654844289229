<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Pass Name*">
              <b-form-input
                v-model="form.pass_name"
                id="input-default"
                placeholder="Pass Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Pass Type">
              <v-select
                :clearable="false"
                v-model="form.pass_type"
                label="text"
                value="value"
                :reduce="(type) => type.value"
                placeholder="Select  Type"
                :options="optionsTypes"
              /> </b-form-group
          ></b-col>

          <b-col cols="12" md="2">
            <b-form-group label="Total Available Pass*">
              <b-form-input
                type="number"
                v-model="form.total_available_pass"
                id="input-default"
                placeholder="Total Pass"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Show Pass From(web)">
              <flat-pickr
                v-model="form.pass_validity_from"
                placeholder="Pass  From"
                class="form-control"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Show Pass Till(web)">
              <flat-pickr
                v-model="form.pass_validity_to"
                placeholder="Pass Till"
                class="form-control"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Pass Validity(days)">
              <b-form-input
                type="number"
                v-model="form.pass_valid_days"
                id="input-default-days"
                placeholder="Pass Validity"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Pass Price">
              <b-form-input
                type="number"
                v-model="form.pass_amount"
                id="input-default-price"
                placeholder="Pass Price"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Payment Currency">
              <v-select
                :clearable="false"
                v-model="form.pass_currency_id"
                label="curr_code"
                value="curr_id"
                :reduce="(currency) => currency.curr_id"
                placeholder="Select Currency"
                :options="currencyListArray"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="2"
            ><b-form-group label="Discount Type">
              <v-select
                disabled
                :clearable="false"
                v-model="form.discount_type"
                label="label"
                value="value"
                :reduce="(discount) => discount.value"
                placeholder="Select Discount Type"
                :options="optionDiscounts"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Discount Value (%)*">
              <b-form-input
                type="number"
                v-model="form.pass_discount_value"
                id="input-default"
                placeholder="Discount Value (%)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Tax Value (%)*">
              <b-form-input
                type="number"
                v-model="form.pass_tax_value"
                id="input-default"
                placeholder="Tax Value (%)"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6"
            ><b-form-group label="Pass Features">
              <quill-editor v-model="form.pass_feature">
              </quill-editor> </b-form-group
          ></b-col>
          <b-col cols="12" md="6"
            ><b-form-group label="Terms & Condition">
              <quill-editor v-model="form.pass_tnc">
              </quill-editor> </b-form-group
          ></b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Max seats limit per transaction*">
              <b-form-input
                type="number"
                v-model="form.max_seats_per_trans"
                id="input-default"
                placeholder="Max Seats limit"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Max transaction per day*">
              <b-form-input
                type="number"
                v-model="form.max_transaction_per_day"
                id="input-default"
                placeholder="Max Transaction Per Day"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Max transaction per User*">
              <b-form-input
                type="number"
                v-model="form.max_transaction_per_user"
                id="input-default"
                placeholder="Max Transaction Per User"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group label="Pass Availability as per">
              <b-form-radio-group
                :disabled="form.pass_id ? true : false"
                @input="onChangeAvailabilty"
                id="radio-group-target"
                v-model="form.pass_target"
                :options="optionsTargets"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col v-if="form.pass_target == 'country'" cols="12" md="12">
            <b-form-group label="Country*">
              <v-select
                multiple
                :clearable="false"
                v-model="form.country_id"
                label="country_name"
                value="country_id"
                :reduce="(country) => country.country_id"
                placeholder="Select Country"
                :options="countryListArray"
              >
                <!-- <template #list-header>
                  <b-badge class="m-1" variant="primary">select all</b-badge>
                </template> -->
              </v-select>
            </b-form-group>
          </b-col>
          <b-col v-if="form.pass_target == 'city'" cols="12" md="12"
            ><b-form-group label="City">
              <v-select
                multiple
                v-model="form.city_id"
                placeholder="Select City"
                :clearable="false"
                label="city_name"
                value="city_id"
                :reduce="(city) => city.city_id"
                :options="cityListArray"
              /> </b-form-group
          ></b-col>
          <b-col v-if="form.pass_target == 'cinema'" cols="12" md="12"
            ><b-form-group label="Cinema/Theatre">
              <v-select
                multiple
                v-model="form.cinema_id"
                placeholder="Select Cinema/Theatre"
                :clearable="false"
                label="cinema_name"
                value="cinema_id"
                :reduce="(cinema) => cinema.cinema_id"
                :options="cinemaListArray"
              >
                <template slot="selected-option" slot-scope="option">
                  <div class="flex">
                    {{ option.cinema_name }} , {{ option.city_name }} ,
                    {{ option.country_name }}
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <span class="fa" :class="option.icon"></span>
                  {{ option.cinema_name }} , {{ option.city_name }} ,
                  {{ option.country_name }}
                </template>
                <!-- <template v-slot:option="option">
                  <span></span>
                  {{ option.cinema_name }} , {{ option.city_name }} ,
                  {{ option.country_name }}
                </template> -->
              </v-select>
            </b-form-group></b-col
          >
        </b-row>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Pass Status">
              <b-form-checkbox v-model="form.pass_is_active" switch>{{
                form.pass_is_active == true ? "Active" : "Disabled"
              }}</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-col cols="12" md="12">
            <br />
            <small
              ><b
                >*Note : Pass will be valid as per event start and event end
                date as provided in the event form.
              </b></small
            >
          </b-col> -->
        <b-row>
          <b-col class="mt-3" md="6" offset-md="3">
            <b-button block variant="primary" @click="onSubmit"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  AddUpdatePassService,
  GetCityListService,
  GetCinemaListService,
  GetCountryListService,
  GetPassListService,
  GetCurrencyListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      form: {
        pass_id: null,
        pass_name: null,
        pass_type: null,
        total_available_pass: 0,
        pass_validity_from: null,
        pass_validity_to: null,
        pass_amount: 0,
        pass_valid_days: 0,
        pass_currency_id: null,
        discount_type: "percent",
        pass_discount_value: 0,
        pass_tax_value: 0,
        pass_tnc: null,
        pass_feature: null,
        max_seats_per_trans: 1,
        max_transaction_per_day: 1,
        max_transaction_per_user: 1,
        pass_target: null,
        is_validate_genre: false,
        is_validate_lang: false,
        pass_is_active: true,
        country_id: [],
        city_id: [],
        cinema_id: [],
        pass_validity: null,
      },
      countryListArray: [],
      cityListArray: [],
      cinemaListArray: [],
      currencyListArray: [],

      optionDiscounts: [{ label: "Percentage", value: "percent" }],
      optionsTargets: [
        { text: "Country", value: "country" },
        { text: "City", value: "city" },
        { text: "Cinema", value: "cinema" },
      ],
      optionsTypes: [
        { text: "All", value: "all" },
        { text: "Event", value: "event" },
        { text: "Movie", value: "movie" },
      ],
    };
  },
  components: {
    vSelect,
    flatPickr,
    quillEditor,
  },
  async beforeMount() {
    this.getCurrencyList();
    if (this.$route.query && this.$route.query.pass_id) {
      this.form.pass_id = this.$route.query.pass_id;
      await this.getPassById(this.$route.query.pass_id);
    }
  },
  methods: {
    async onChangeAvailabilty() {
      if (this.form.pass_target == "country") {
        this.getCountryList();
      } else if (this.form.pass_target == "city") {
        this.getCityList();
      } else if (this.form.pass_target == "cinema") {
        this.getCinemaList();
      }
    },
    async getCountryList() {
      try {
        const response = await GetCountryListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.countryListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCityList() {
      try {
        const response = await GetCityListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.cityListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        this.form.pass_is_active = this.form.pass_is_active == true ? "Y" : "N";
        this.form.is_validate_genre =
          this.form.is_validate_genre == true ? "Y" : "N";
        this.form.is_validate_lang =
          this.form.is_validate_lang == true ? "Y" : "N";
        const response = await AddUpdatePassService(this.form);
        if (response.data.status) {
          this.$router.push({
            name: "passlist",
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPassById(user_id) {
      try {
        const response = await GetPassListService({
          pass_id: this.form.pass_id ? this.form.pass_id : null,
        });

        if (response.data.status) {
          if (response.data && response.data.Records) {
            let passAry = response.data.Records[0];
            Object.keys(passAry).map((x) => {
              this.form[x] = passAry[x];
              if (x == "pass_is_active") {
                this.form[x] = passAry[x] == "Y" ? true : false;
              }

              if (x == "is_validate_genre") {
                this.form[x] = passAry[x] == "Y" ? true : false;
              }

              if (x == "is_validate_lang") {
                this.form[x] = passAry[x] == "Y" ? true : false;
              }
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCinemaList() {
      try {
        const response = await GetCinemaListService({
          limit: 10000,
        });

        if (response.data.status) {
          this.cinemaListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGenreList() {
      try {
        const response = await GetGenreListService({
          limit: 10000,
        });
        if (response.data.status) {
          this.genreListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getLanguageList() {
      try {
        const response = await GetLanguageListService({
          limit: 10000,
        });
        if (response.data.status) {
          this.languageListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCurrencyList() {
      try {
        const response = await GetCurrencyListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.currencyListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
